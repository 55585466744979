@import 'variables';
@import 'theme-variables';
@import 'modals-form';

@mixin smallLabel {
  font-weight: 500;
  font-size: pxToRem(12px);
  line-height: pxToRem(18px);
}

@mixin smallLabelText {
  font-weight: 600;
  font-size: pxToRem(14px);
  line-height: pxToRem(16px);
}

@mixin head1 {
  font-weight: 600;
  font-size: pxToRem(20px);
  line-height: pxToRem(25px);
}
