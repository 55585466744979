.form--export {
  .btn-group {
    display: flex;
    justify-content: right;
    gap: 10px;
  }

  input,
  .MuiTypography-root {
    font-size: pxToRem(14px);
  }

  label {
    font-size: pxToRem(12px);
  }

  .MuiInputBase-root {
    padding: 0;
  }
}

.property-field {
  padding: 5px 0;

  .property-label {
    display: block;
    font-size: 12px;
    line-height: 12px;

    @include themify($themes) {
      color: themed('secondaryHoverColor');
      font-weight: 600;
    }
  }

  .property-value {
    font-size: 14px;

    @include themify($themes) {
      color: themed('primaryColor');
    }

    &.bold {
      font-weight: bold;
    }

    &.small {
      font-size: 12px;
      white-space: initial;
    }
  }
}
