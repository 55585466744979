.Toastify__close-button {
  align-self: inherit;
  color: $color_neutral_6;
}
.Toastify__close-button--light,
.popover--notifications .icon-close {
  svg {
    font-size: 20px;
  }
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
}

.Toastify__toast {
  user-select: none;
  border-radius: 0;
}

.Toastify__toast-body {
  @include smallLabel;
  color: $color_neutral_7;
}

.theme-color-blind {
  .Toastify__toast-body {
    color: $color_white;
  }

  .MuiAlert-standardSuccess,
  .MuiAlert-standardInfo {
    background: $color_black;
    border: none;
    margin: 0;
    color: $color_white;
  }
}

.Toastify__toast--warning .Toastify__toast-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  background: $color_yellow_9;
  svg {
    margin: auto;
    color: $color_yellow_8;
  }
}

.popover--notifications {
  .no-items {
    user-select: none;
    padding: 10px;
    background: $color_yellow_9;
    font-size: pxToRem(12px);
    line-height: pxToRem(18px);
  }

  .head button {
    text-transform: none;
    font-weight: 600;
  }

  h6 {
    font-size: pxToRem(18px);
    line-height: pxToRem(22px);
    margin-bottom: 5px;
  }

  .notification-content {
    width: 250px;
    height: 36px;
    @include smallLabel;

    span {
      @include smallLabel;

      span {
        font-weight: bold;
        @include themify($themes) {
          color: themed('primaryText') !important;
        }
      }
    }
  }
}
