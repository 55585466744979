$themes: (
  default: (
    // teal_green
    primaryColor: #000,
    primaryText: #003f33,
    primaryAltText: $color_blue_4,
    primaryHoverColor: #edf2f2,
    disabledColor: #000,
    disabledText: #ccc,

    // steel_teal
    secondaryColor: #5f8a8b,
    secondaryText: #ffffff,
    secondaryHoverColor: #5f8a8b,
    lightBorderColor: #dee2e6,

    tertiaryText: $color_neutral_8,

    backgroundColor: #ffffff,
    backgroundAltColor: $color_blue_1,
    backgroundEvenColor: #f8f9fa,
    backgroundSecondaryColor: $color_neutral_1,
    backgroundSuccessColor: #008c49,
    backgroundHover: $color_teal_1,
    mapFilter: none,
  ),
  color-blind: (
    primaryColor: #fefd32,
    primaryText: #fefd32,
    primaryAltText: #000,
    primaryHoverColor: #4f575e,
    disabledColor: #000,
    disabledText: #ccc,

    secondaryColor: #fefd32,
    secondaryText: #000000,
    secondaryHoverColor: #fff,
    lightBorderColor: #fefd32,

    tertiaryText: #fefd32,

    backgroundColor: #000000,
    backgroundAltColor: #fefd32,
    backgroundEvenColor: #4f575e,
    backgroundSecondaryColor: #000000,
    backgroundSuccessColor: #fefd32,
    backgroundHover: #4f575e,

    mapFilter: brightness(1) invert(1) contrast(1) hue-rotate(200deg) saturate(1) brightness(1),
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
