@use 'sass:math';

// Primary
$color_primary: #000;
$color_primary_teal_green: #003f33;
$color_primary_platinum: #edf2f2;

// Color Blind
$color_blind_primary: #fefd32;

// Secondary
$color_secondary_steel_teal: #5f8a8b;

// Tertiary
$color_tertiary_steel_grey: #989e9c;
$color_tertiary_taupe_grey: #898878;
$color_tertiary_citron: #b6b63a;
$color_tertiary_dark_olive: #3a391b;
$color_tertiary_forest_green: #002b24;

// Neutral
$color_white: #ffffff;
$color_neutral_1: #f8f9fa;
$color_neutral_2: #f1f3f5;
$color_neutral_3: #e9ecef;
$color_neutral_4: #dee2e6;
$color_neutral_5: #ced4da;
$color_neutral_6: #adb5bd;
$color_neutral_7: #6a7178;
$color_neutral_8: #4f575e;
$color_neutral_9: #272b30;
$color_neutral_10: #101213;
$color_black: #000000;

// Red
$color_red_1: #faeaea;
$color_red_2: #e9aaaa;
$color_red_3: #d96a6a;
$color_red_4: #c92a2a;
$color_red_5: #ab2424;
$color_red_6: #8c1d1d;
$color_red_7: #6e1717;

// Orange
$color_orange_1: #fef0e6;
$color_orange_2: #fcc29c;
$color_orange_3: #f99551;
$color_orange_4: #f76707;
$color_orange_5: #d25705;
$color_orange_6: #ad4805;
$color_orange_7: #883904;

// Yellow
$color_yellow_1: #fef5e5;
$color_yellow_2: #fbd999;
$color_yellow_3: #f8bc4c;
$color_yellow_4: #f59f00;
$color_yellow_5: #d08700;
$color_yellow_6: #ac6f00;
$color_yellow_7: #875700;
$color_yellow_8: #e5bb00;
$color_yellow_9: #fdf6e5;

// Lime
$color_lime_1: #f8f8eb;
$color_lime_2: #c7e3a2;
$color_lime_3: #9ecd5c;
$color_lime_4: #74b816;
$color_lime_5: #639c13;
$color_lime_6: #51810f;
$color_lime_7: #40650c;

// Green
$color_green_1: #ebf7ed;
$color_green_2: #afe0b8;
$color_green_3: #73c982;
$color_green_4: #37b24d;
$color_green_5: #2f9741;
$color_green_6: #267d36;
$color_green_7: #1e622a;

// Teal
$color_teal_1: #e6f2ef;
$color_teal_2: #9cccbd;
$color_teal_3: #52a58c;
$color_teal_4: #087f5b;
$color_teal_5: #076c4d;
$color_teal_6: #065940;
$color_teal_7: #044632;

// Cyan
$color_cyan_1: #e7f2f4;
$color_cyan_2: #9eccd3;
$color_cyan_3: #56a6b2;
$color_cyan_4: #0d8091;
$color_cyan_5: #0b6c7b;
$color_cyan_6: #095a66;
$color_cyan_7: #074650;

// Blue
$color_blue_1: #e5f0fe;
$color_blue_2: #a3c9ea;
$color_blue_3: #5fa1db;
$color_blue_4: #1a79cb;
$color_blue_5: #1667ad;
$color_blue_6: #12558e;
$color_blue_7: #0e4370;

// Indigo
$color_indigo_1: #eceffd;
$color_indigo_2: #b3c1f7;
$color_indigo_3: #7b92f1;
$color_indigo_4: #4263eb;
$color_indigo_5: #3854c8;
$color_indigo_6: #2e45a5;
$color_indigo_7: #243681;

// Violet
$color_violet_1: #f1edfd;
$color_violet_2: #c6b6f6;
$color_violet_3: #9b7fef;
$color_violet_4: #7048e8;
$color_violet_5: #5f3dc5;
$color_violet_6: #4e32a2;
$color_violet_7: #3e2880;

// Grape
$color_grape_1: #f7ecfa;
$color_grape_2: #dfb2e9;
$color_grape_3: #c678d9;
$color_grape_4: #ae3ec9;
$color_grape_5: #9435ab;
$color_grape_6: #7a2b8d;
$color_grape_7: #60226f;

// Pink
$color_pink_1: #fbebf0;
$color_pink_2: #efadc4;
$color_pink_3: #e27098;
$color_pink_4: #d6336c;
$color_pink_5: #b62b5b;
$color_pink_6: #96244c;
$color_pink_7: #761c3b;

// Info
$color_info_light: #e5f5fc;
$color_info_base: $color_info_light;

// Success
$color_success_light: #eaf3eb;
$color_success_base: #008c49;

// Warning
$color_warning_light: #fdf6e5;
$color_warning_base: #e5bb00;

// Error
$color_error_light: #fceaea;
$color_error_base: #d32f2f;

// Focus
$color_focus_inner: $color_neutral_10;
$color_focus_outer: #ffd337;

// Convert px to rem
@function pxToRem($pxValue) {
  @return math.div($pxValue, 16px) * 1rem;
}
